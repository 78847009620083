.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.Loader {
  width: calc(100vw - 64px);
  height: calc(100vh - 400px);
}

.Loader > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mb5 {
  margin-bottom: 64px;
}

.wrap {
  flex-wrap: wrap;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Search-Box {
  padding: 11px 16px;
  width: 100%;
  line-height: 1.3;
  min-width: 250px;
  max-width: 420px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(83, 91, 98, 0.25);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  color: #2f363f;
  margin-right: 24px;
}

.Search-Box:focus {
  border: solid 1px rgba(31, 122, 224, 0.6);
  outline: none;
}

.Search-Button {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px transparent;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-transition: -webkit-transform 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  transition: -webkit-transform 0.15s ease, -webkit-box-shadow 0.15s ease;
  -o-transition: box-shadow 0.15s ease, -o-transform 0.15s ease;
  -moz-transition: transform 0.15s ease, box-shadow 0.15s ease,
    -moz-transform 0.15s ease, -moz-box-shadow 0.15s ease;
  transition: transform 0.15s ease, box-shadow 0.15s ease;
  transition: transform 0.15s ease, box-shadow 0.15s ease,
    -webkit-transform 0.15s ease, -moz-transform 0.15s ease,
    -o-transform 0.15s ease, -webkit-box-shadow 0.15s ease,
    -moz-box-shadow 0.15s ease;
  background-color: #1f7ae0;
  border: 1px solid #1f7ae0;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  padding: 11px 13px;
  line-height: 1.3;
  max-width: 420px;
  min-width: 100px;
}

@media only screen and (max-width: 768px) {
  .Search-Box {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .Search-Button {
    width: 100%;
  }

  .mb5 {
    margin-bottom: 48px;
  }
}

.react-autosuggest__container {
  position: relative;
  margin-right: 24px;
}

.react-autosuggest__input {
  padding: 11px 16px;
  width: 100%;
  line-height: 1.3;
  min-width: 250px;
  max-width: 420px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(83, 91, 98, 0.25);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  color: #2f363f;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  text-align: left;
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.footer {
  margin: 9px 20px;
  font-size: 12px;
  color: #777;
}

@media only screen and (max-width: 768px) {
  .react-autosuggest__container {
    margin-right: 0px !important;
    width: 100%;
    margin-bottom: 12px;
  }
}
